import React from 'react';

import { useMediaQuery } from '@uidotdev/usehooks'
import { HBox } from './HBox';
import { Avatar, Typography } from '@mui/joy';
import { VBox } from './VBox';

export function UserView({
    user,
    role,
    label,
    expanded=false
}) {
  const isMobile = useMediaQuery('screen and (max-width: 600px)')
    return (
        <HBox>
            <Avatar size="sm" src={user.imagerl} />
            <VBox sx={{ flex: 1 }}>
                <Typography>{user?.username}</Typography>
                {user?.role && <Typography>{user?.role}</Typography>}
            </VBox>
        </HBox>
    )
}