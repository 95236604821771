import React, { useState } from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/joy';

import i18n from '@/i18n'

import { Loading } from '@/components/Loading';
import { useParams } from 'react-router-dom';

import { StatisticsContainer } from '@/pages/dashboard/statistics';

import { useHostname } from '@/contexts';
import { HBox } from '@/components/HBox';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useResource } from '@/hooks';
import { UserView } from '@/components/UserView';
import { Do, Empty, ForEach } from '@/components/Syntax';
import { ReleaseCard } from '@/components/ReleaseCard';
import { Table } from '@/components/Table';
import { PlayButton } from '@/components/PlayButton';

  export function RecordingView({ recordingId }) {
    const hostname = useHostname()
    const navigate = useNavigate()

    const [values, setValues] = useState({})

  const { data: recording, loading } = useResource({
    queryKey: ['recording', recordingId],
    path: `/recording/${recordingId}`
  })

  const { data: releases, loadingReleases } = useResource({
    queryKey: ['recording', recordingId, 'releases'],
    path: `/recording/${recordingId}/releases`
  })
  if (loading) {
    return <Loading />
  }

  if (recording) {
    return (
      <Container>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
          <HBox>
            
            {recording.has_audio && (
              <PlayButton size={56} recording={recording} />
            )}
            <Typography level="h1">{recording.name}</Typography>
          </HBox>
          <Typography level="h2">{recording.primary_genre?.name}</Typography>
          {!(recording?.has_audio) && (
            <Typography>{i18n.t('recording-is-missing-audio')}</Typography>
          )}
          <Button
            component={Link}
            to={`/dashboard/recording/${recording.id}/edit`}
            variant="outlined"
          >{i18n.t('edit')}</Button>
          <h3>{i18n.t('contributors')}</h3>
          <HBox>
            <ForEach collection={recording.recording_contributors}>
              <Do>
                {contributor => (
                  <span key={contributor.id}>
                    <UserView
                      user={{ username: contributor.person.name, imageUrl: contributor.person.image_url }}
                      role={contributor?.roles?.map(r => r.name).join(', ')}
                    />
                  </span>
                )}
              </Do>
              <Empty>
                <i>Recording has no contributors</i>
              </Empty>
            </ForEach>
          </HBox>
          <h3>{i18n.t('royalty-splits')}</h3>
          <Table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <ForEach collection={recording.recording_payees}>
              <Do>
                {recordingPayee => (
                  <tr key={recordingPayee.id}>
                    <td>
                      <UserView key={recordingPayee.id} user={{ username: recordingPayee.payee.name }} />
                    </td>
                    <td style={{ textAlign: 'left' }}>
                      {recordingPayee.payee.email}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {recordingPayee.percentage} %
                    </td>
                  </tr>
                )}
              </Do>
              <Empty>
                <tr>
                  <td colSpan={3}>
                    <UserView user={{ username: '' }} />
                  </td>
                  <td style={{ textAlign: 'left' }}>
                    
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    100 %
                  </td>
                </tr>
              </Empty>
            </ForEach>
            <tr className="tr-summary">
              <td>Total</td>
              <td />
              <td style={{ textAlign: 'right' }}>100 %</td>
            </tr>
          </Table>
          <h3>{i18n.t('artists')}</h3>
          <HBox>
            <ForEach collection={recording.recording_artists}>
              <Do>
                {recordingArtist => (
                  <UserView key={recordingArtist.id} user={{ username: recordingArtist.artist.name }} />
                )}
              </Do>
              <Empty>
                <i>Recording has no artists</i>
              </Empty>
            </ForEach>
          </HBox>
          {recording.is_distributed && (
            <StatisticsContainer
              recordingIds={[recording.id]}
            />
          )}
          <h3>{i18n.t('in-releases')}</h3>
          <Grid sx={{ alignSelf: 'stretch' }} container spacing={2}>
            <ForEach collection={releases?.results }>
              <Do>
                {(release, i) => (
                  <Grid key={release.id} md={3}> 
                    <ReleaseCard
                      sx={{ p: 0, transitionDelay: `${i * 100}ms`}}
                      component={Link}
                      to={`/dashboard/release/${release.id}`}
                      {...release}
                    />
                  </Grid>
                )}
              </Do>
              <Empty>
                <i>Recording has not yet been added to any releases</i>
              </Empty>
            </ForEach>
          </Grid>
        </Box>
      </Container>
    )
  }
}

export default function RecordingPage() {
  const { recordingId } = useParams()

  return (
    <React.Suspense fallback={<Loading />}>
      <RecordingView recordingId={recordingId} />
    </React.Suspense>
  )
}